<template>
  <b-card
    bg-variant="light"
    class="text--black"
  >
    <b-overlay :show="loading">
      <b-form
        @submit="load"
        @reset="reset"
        inline
        class="justify-content-center"
      >
        <b-form-group
          label="Industry"
          label-cols="auto"
          class="mx-3 mt-2"
          :disabled="loading || record !== null"
        >
          <b-form-select
            v-model="form.industry_id"
            @change="form.industryrevenuebucket_id = null"
            :options="industries"
            size="sm"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group
          label="Year"
          label-cols="auto"
          class="mx-3 mt-2"
          :disabled="loading || record !== null"
        >
          <b-form-select
            v-model="form.year"
            :options="years"
            size="sm"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group
          label="Quarter"
          label-cols="auto"
          class="mx-3 mt-2"
          :disabled="loading || record !== null"
        >
          <b-form-select
            v-model="form.quarter"
            :options="quarters"
            size="sm"
            required
            >
          </b-form-select>

        </b-form-group>

        <b-form-group
          label="Bucket"
          label-cols="auto"
          class="mx-3 mt-2"
          :disabled="loading || record !== null"
          v-if="bucketsForIndustry && bucketsForIndustry.length > 0"
        >
          <b-form-select
            v-model="form.industryrevenuebucket_id"
            :options="bucketsForIndustry"
            size="sm"
          >
            <template #first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>

        <b-button-group>
          <b-button
            type="submit"
            variant="primary"
            size="sm"
            :disabled="loading || record !== null"
          >Load</b-button>
          <b-button
            type="reset"
            variant="danger"
            size="sm"
            :disabled="loading || record === null"
          >Reset</b-button>
        </b-button-group>
      </b-form>

      <div v-if="form && record">
        <b-card
          v-if="+record.id"
          no-body
          class="mt-3"
        >
          <b-card-header>
            <b-card-title class="text-left m-0">{{ record.industry.name }} Industry Averages for {{ form.year }}<span v-if="form.quarter"> Q{{ form.quarter }}</span></b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="text-left"
              >
                <span v-if="record.updated">Updated by: {{ record.updatedby }} @ {{ record.updated }}</span>
                <span v-else>Created by: {{ record.createdby }} @ {{ record.created }}</span>
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="text-right"
                v-if="record.status"
              >
                Status: {{ record.status }} @ {{ record.statustime }}
                <b-icon-bootstrap-reboot
                  v-if="isProcessing"
                  class="pointer text-info"
                  @click.prevent="load"
                ></b-icon-bootstrap-reboot>
              </b-col>
            </b-row>
          </b-card-body>

          <b-table
            v-if="record.averages.length"
            sticky-header
            :items="record.averages"
            :fields="fieldsaverages"
            outlined
            bordered
            small
            striped
            hover
            class="mb-0 small"
          >
            <template #cell(total)="data">
              {{ Math.abs(data.item.total) | currency }}
            </template>
            <template #cell(percentofincome)="data">
              {{ data.item.percentofincome }}%
            </template>
          </b-table>
        </b-card>

        <AvailableCompanies
          :companies="form.companies"
          :is-processing="isProcessing"
          @update="(val) => { form.companies = val }"
        ></AvailableCompanies>

        <div class="m-3">
          <b-button
            variant="success"
            @click.prevent="confirmSave"
            :disabled="loading || !hasChanges || isProcessing"
            class="mx-1"
            size="sm"
          ><span v-if="+form.id">Update</span><span v-else>Create</span></b-button>
          <b-button
            @click.prevent="reset"
            :disabled="loading"
            class="mx-1"
            size="sm"
          >Cancel</b-button>
        </div>

        <router-link to="/admin/industry" class="text--black">Back to List</router-link>
      </div>
    </b-overlay>
  </b-card>
</template>
<script>
import IndustryService from '@/services/IndustryService'
import AvailableCompanies from '@/components/admin/industry/AvailableCompanies'

export default {
  name: 'IndustryAverageEdit',

  components: { AvailableCompanies },

  props: {
    id: {
      type: [Number, String],
      default () { return null }
    }
  },

  data () {
    return {
      loading: false,
      form: {
        id: null,
        industry_id: null,
        industryrevenuebucket_id: null,
        year: null,
        quarter: null,
        companies: []
      },
      record: null,
      industries: null,
      buckets: [],
      years: [],
      quarters: [1, 2, 3, 4],
      originalCompanies: [],
      fieldscompanies: [
        { key: 'id', label: '', sortable: false, tdClass: 'align-middle' },
        { key: 'company', sortable: true, thClass: 'text-left', tdClass: 'align-middle text-left' },
        { key: 'income', sortable: true, thClass: 'text-right', tdClass: 'align-middle text-right' },
        { key: 'expense', sortable: true, thClass: 'text-right', tdClass: 'align-middle text-right' }
      ],
      fieldsaverages: [
        { key: 'accounttype', label: 'Account Type', sortable: true, tdClass: 'align-middle' },
        { key: 'accountname', label: 'Account', sortable: true, thClass: 'text-left', tdClass: 'align-middle text-left' },
        { key: 'total', label: 'Average', sortable: true, thClass: 'text-right', tdClass: 'align-middle text-right' },
        { key: 'percentofincome', label: '% of Income', sortable: true, tdClass: 'align-middle' }
      ]
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      this.loading = true
      IndustryService.init().then((response) => {
        if (response.data.result) {
          this.industries = []
          response.data.info.industries.forEach((i) => {
            this.industries.push({ value: +i.id, text: i.name })
            this.buckets.push({ industry_id: +i.id, buckets: i.buckets })
          })

          if (+this.id > 0) {
            this.form.id = +this.id
          }
        }

        const dt = new Date()
        const y = dt.getFullYear() - 5
        for (let i = y; i <= dt.getFullYear(); i++) {
          this.years.push(i)
        }
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
        if (this.form.id) {
          this.load()
        }
      })
    },

    load (event) {
      if (event) {
        event.preventDefault()
      }
      this.loading = true
      IndustryService.load(this.form).then((response) => {
        if (response.data.result) {
          this.record = response.data.info
          this.form.id = this.record.id
          this.form.industry_id = this.record.industry_id
          this.form.industryrevenuebucket_id = this.record.industryrevenuebucket_id
          this.form.year = this.record.year
          this.form.quarter = this.record.quarter
          this.form.companies = this.record.companies
          if (this.record.id) {
            this.originalCompanies = []
            this.form.companies.forEach((c) => {
              this.originalCompanies.push({ id: +c.company_id, selected: c.selected })
            })
          }
        } else {
          console.dir(response.data)
        }
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    confirmSave () {
      if (this.loading) {
        return false
      }

      if (+this.form.id) {
        // existing record, confirm overwrite before saving
        this.$bvModal.msgBoxConfirm('Are you sure you want to overwrite this record?', { title: 'Overwrite Record?' }).then((val) => {
          if (val) {
            return this.save()
          }
        }).catch((err) => {
          this.$aimNotify.error(err.response)
        })
      } else {
        return this.save()
      }
    },

    save () {
      if (this.loading) {
        return false
      }

      this.loading = true
      IndustryService.save(this.form).then((response) => {
        if (response.data.result) {
          this.form.id = response.data.info.id
          this.load()
          this.$aimNotify.notify(response, 'info', 'Saved Successfully', 'Your changes were saved successfully.')
        } else {
          this.$aimNotify.error(response, 'error', 'Save Failed', 'Failed to save changes!')
        }
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    reset (event) {
      if (event) {
        event.preventDefault()
      }
      if (this.loading) {
        return false
      }
      this.form.id = null
      this.form.companies = []
      this.record = null
      if (+this.id) {
        this.$router.push('/admin/industry/edit/')
      }
    }
  },

  computed: {
    hasChanges () {
      if (this.form && this.form.companies) {
        const cur = []
        this.form.companies.forEach((c) => {
          cur.push({ id: +c.company_id, selected: c.selected })
        })
        if (JSON.stringify(cur) !== JSON.stringify(this.originalCompanies)) {
          return true
        }
      }
      return false
    },

    isProcessing () {
      if (this.record && this.record.status && this.record.status !== 'Completed') {
        return true
      }
      return false
    },

    bucketsForIndustry () {
      if (this.form && this.form.industry_id) {
        const buckets = []
        this.buckets.some(e => {
          if (+e.industry_id === +this.form.industry_id) {
            e.buckets.forEach(b => {
              const minTxt = this.$convertToCurrency(b.min, false)
              const maxTxt = this.$convertToCurrency(b.max, false)
              buckets.push({ value: +b.id, text: (b.max ? minTxt + ' - ' + maxTxt : minTxt + '+') })
            })
            return true
          }
          return false
        })

        return buckets
      }

      return []
    }
  }
}
</script>
